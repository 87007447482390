import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Img from "gatsby-image"
import { graphql } from "gatsby"
import "../assets/css/template.css"

const Generic = (props) => (
    <Layout links={props.data.menu.edges}>
      <Helmet>
        <title>{props.pageContext.title} - Edward Beazer Portfolio</title>
        <meta name="description" content="Generic Page" />
      </Helmet>
      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>{props.pageContext.title}</h1>
            </header>
            <Img
              style={{ width: "100%", display: "block", marginBottom: 25 }}
              fluid={props.data.cover.edges[0].node.cover.fluid}
              alt=""
            />
            <div
              className="htmlContainer"
              dangerouslySetInnerHTML={{
                __html: props.pageContext.html
              }}
            />
          </div>
        </section>
      </div>
    </Layout>
)

export const coverImage = graphql`
  query($slug: String!) {
    cover: allContentfulProject(filter: { slug: { eq: $slug } })  {
      edges {
        node {
          cover {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    menu: allContentfulProject {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`

export default Generic
